/*terms&conditions starts*/
.terms-and-conditions-container{
    position: relative;
    padding:150px 30px;
}
.terms-and-conditions-container .tabcontent-container{
    width: 100%;
    max-width: 1200px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: auto;
}
.terms-and-conditions-container a{
    font-weight: 600;
}
.terms-and-conditions-container p{
    padding: 5px 0;
}
.terms-and-conditions-container h3{
    padding: 10px 0;
}
.terms-and-conditions-container li{
    padding: 5px 0;
    font-size: var(--pxft-font-normal);
}

.tabcontent-container .tab{
    display: flex;
    justify-content: space-around;
    border-bottom: 1px solid var(--pxft-mildgrey);
    padding: 24px 0 0 0;
}
.tabcontent-container .tab-mobile{
    display: none;
}
@media only screen and (max-width: 899px) {
    .tabcontent-container .tab{
        display: none;
    }
    .tabcontent-container .tab-mobile{
        display: block;
    }
}

.terms-and-conditions-container .tablinks{
    font-size: 1.35em;
    font-weight: 300;
    line-height: normal;
    letter-spacing: normal;
    color: var(--mid-gray);
    display: inline-block;
    padding: 0 10px 14px;
    border-bottom: 3px solid transparent;
    transition: ease-in ease-out 3s;
}

.terms-and-conditions-container .tablinks a{
    text-decoration: none;
}
.terms-and-conditions-container .tablinks:hover {
    color: var(--pxft-primary);
}

.terms-and-conditions-container .policy-title{
    color:var(--pxft-primary);
    padding:10px 0;
    font-weight: 600;
}

.terms-and-conditions-container .tab-active {
color: var(--pxft-primary);
border-color:var(--pxft-primary);
}

.terms-and-conditions-container .tabcontent{
    width:100%;
    justify-content: center;
    display: none;
}

.terms-and-conditions-container .tabcontent ul{
    padding: 0 0 0 30px;
}

.terms-and-conditions-container .tabcontent span::before{
    content: "\a\00a0\00a0\00a0\00a0";
    white-space: pre;
}
.terms-and-conditions-container .updated-time{
    color:var(--pxft-midgrey);
    font-size: 13px;
    padding:20px 0;
}

/*terms&conditions ends*/

/*support starts*/
.support-container{
    position: relative;
    padding:150px 30px;
}
.support-container p{
    text-align: center;
}
/*support ends*/

/*cmp starts*/
.cmp-container{
    position: relative;
    padding:0px 30px 150px;
}
/*cmp ends*/
