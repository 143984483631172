/*header starts*/
.home-header-container {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
}
.home-header-container .particles{
    position: absolute;
    top:0;
    width:100vw;
    height:100vh;
    z-index: 0;
}
.home-background-img img{
    position:absolute;
    top:0;
    width: 100%;
}
.home-title-container{
    position: relative;
    display: flex;
    height: 100vh;
    width: 60%;
    /* margin:120px 0; */
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 1;
}

.home-header-container .home-news{
    margin:20px 0;
}
.home-news .news-container{
    border-top:2px solid var(--pxft-darkgrey);
    border-bottom:2px solid var(--pxft-darkgrey);
    padding:10px;
    background-color: white;
    display: flex;
    flex-direction: row;
    max-width: 440px;
}
.news-container .pic{
    display: flex;
    flex-direction: column;
    align-items: center;
    color:black;
}
.news-container .content{
    padding:10px 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color:var(--pxft-darkgrey);
}
.home-title-container .home-title{
    position: absolute;
    width: 160%;
    height: 60%;
    display: flex;
    flex-direction: column;
}
.home-title .words-container{
    padding: 70px 0 90px 0;
    z-index: 1;
}

.home-title .title{
    width:100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: auto;
    padding: 0 0 10px 0;
}

.home-title h1{
    color: black;
    font-size: var(--pxft-font-title);
    font-weight: var(--pxft-font-exbold);
    text-align: center;
    line-height: 1.3;
    margin:auto;
}
.home-title p{
    padding:5px 0;
    width: 85%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin:auto;
    line-height: 0.8;
    letter-spacing: 0.8px;
}
.home-title .shapes{
    display: flex;
    justify-content: center;
    z-index: 0;
}
.home-title .block{
    position: absolute;
    top:0;
    z-index: 1;
}
.home-title .rectangle{
    position: absolute;
    top:-40px;
}

.home-title .btn-container {
    display: flex;
    justify-content: center;
    z-index: 1;
}

.home-title .btn-container button{
    width:180px;
    height: 50px;
}
.home-title .btn-container p{
    margin:0;
}
.home-header-container .home-badge-contianer{
    position: absolute;
    bottom: 20px;
}
.home-header-container .home-badge{
    max-width: 750px;
}
.home-header-container .home-badge .badge-img img{
    max-height: 120px;
    max-width: 120px;
    padding: 10px 0;
}
.home-header-container .home-badge .badge-img-five img{
    max-width: 300px;
}
@media only screen and (min-width: 1499px){
    /* .home-title-container{
        height:80vh;
    } */
    .home-header-container .home-badge .badge-img img{
        max-width: 150px;
    }
    .home-header-container .home-badge .badge-img-five img{
        max-width: 300px;
    }
}

@media only screen and (min-width: 499px) and (max-width: 899px) {
    .home-title .words-container{
        padding: 20px 0 60px 0;
    }
    .home-title-container{
        height:100vh;
    }
    .home-title .title{
        width: 70%;
    }
    .home-title h1{
        font-size: var(--pxft-font-xxxl);
    }
}
@media only screen and (max-width: 499px) {
    .home-header-container {
        height:auto;
    }
    .home-title-container{
        height:100vh;
        width: 80%;
    }
    .home-news .news-container{
        width: 100%;
    }
    .home-news h3{
        font-size:large;
    }
    .home-news h6{
        font-size: x-small;
    }
    .home-title{
        align-items: center;
    }

    .home-title .words-container{
        padding: 20px 0 60px 0;
        width: 50%;
    }
    .home-title h1{
        font-size: var(--pxft-font-xxxl);
    }
    .home-title .rectangle{
        width: 100vw;
        max-width: 450px;
    }
    .home-header-container .home-badge{
        max-width: 100%;
    }
    .home-header-container .home-badge-contianer{
        bottom: 5%;
    }
    .home-header-container .home-badge .badge-img img{
        max-height: 100px;
        width: 90%;
    }
}
/*header ends*/

/*overview starts*/
.home-latest-news-container{
    position: relative;
    background-color: var(--pxft-lightgrey);
    overflow: hidden;
    padding: 120px 30px 60px;
    flex-direction: column;
}
.home-overview-container {
    position: relative;
    background-color: var(--pxft-lightgrey);
    overflow: hidden;
    padding: 0 0 80px 0;
}

.overview-pic {
    position: relative;
}

.home-overview-container .svglines{
    position: absolute;
    width: 90%;
    max-width: 670px;
    left:7%;
    top:7%;
    z-index: 1;
}
.home-overview-container .overview-left img{
    width:100%;
    max-width: 760px;
}
/*overview ends*/

/*monetization starts*/
.home-monetization-container{
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.home-monetization-container .monetization-content{
    position: relative;
    display: flex;
    flex-direction: row;
    padding: 50px 30px;
    max-width: 1600px;
}
.monetization-content .monetize-tabs{
    position:sticky;
    top:10%;
    border-right: 1px solid var(--pxft-mildgrey);;
}

.monetization-content .monetize-tab-pic{
    position: relative;
    padding: 50px 0;
    max-width: 760px;
}
.monetization-content .monetize-tab-pic .tabs{
    display: none;
}

@media only screen and (min-width: 599px) and (max-width: 899px) {
    .monetization-content .monetize-tabs{
        display: none;
    }
    .monetization-content .monetize-tab-pic{
        padding: 10px 0;
    }
    .monetization-content .monetize-tab-pic .tabs{
        display: block;
        margin:0 20px;
        padding: 10px 0;
        border-bottom: 1px solid var(--pxft-mildgrey);
        text-align: center;
    }
    .monetization-content .monetize-tab-pic .tabs h4{
        color:black;
        padding: 10px 0;
    }
}
@media only screen and (max-width: 599px) {
    .monetization-content .monetize-tabs{
        display: none;
    }
    .monetization-content .monetize-tab-pic{
        padding: 10px 0;
    }
    .monetization-content .monetize-tab-pic .tabs{
        display: block;
        margin:0 20px;
        padding: 10px 0;
        border-bottom: 1px solid var(--pxft-mildgrey);
        text-align: center;
    }
    .monetization-content .monetize-tab-pic .tabs h4{
        color:black;
        padding: 10px 0;
    }
    
}
/*monetization ends*/
/*plartform starts*/
.home-our-platform-container{
    position: relative;
    background-color: var(--pxft-lightgrey);
}
.home-our-platform-container .home-our-platform-content{
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 0 30px 0;
}

.home-our-platform-container .home-our-platform-content h1{
    color:black;
    text-align: center;
}
/*platform ends*/

/*productDemo starts*/
.home-productdemo-container{
    position: relative;
    padding: 50px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.home-productdemo-container .home-steps{
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 40px 0;
}

@media only screen and (max-width: 599px) {
    .home-productdemo-container .home-productdemo-down{
        padding: 30px 0;
    }
    .home-productdemo-container .home-productdemo-down .pics-container .imgbox{
        padding:5px;
    }
    .home-productdemo-container .home-productdemo-down .pics-container .left-imgbox{
        padding:5px 5px 5px 0;
    }
    .home-productdemo-container .home-productdemo-down .pics-container .right-imgbox{
        padding:5px 0 5px 5px;
    }
}


/*productDemo ends*/

/*homeArticle starts*/
.home-article-container {
    padding: 0 0 100px 0;
}

.home-article-container .ad-container{
    padding: 50px 0;
}
.home-article-container .inventory-container{
    padding: 50px 0;
}
/**homeArticle ends*/