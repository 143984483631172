.buyer-container{
    position: relative;
    background-color: var(--pxft-lightgrey);
    padding:50px 0;
}
.buyer-container .header{
    flex-direction: column;
}

.gostory-header-container{
    position: relative;
    background-color: var(--pxft-lightgrey);
    height: 100vh;
    display: flex;
    align-items: center;
    padding: 130px 0;
    max-height: 760px;
}
.gostory-title {
    display: flex;
    flex-direction: row;
    color:black;
    text-align:left;
    line-height:1.3;
    white-space: pre-line;
}

.gostory-line {
    padding: 0 12px;
    border-left:2px solid var(--pxft-primary);
}

.gostory-txt {
    padding:40px 0;
    line-height:1.3;
    white-space: pre-line;
}

@media only screen and (max-width: 599px) {
    .gostory-header-container{
        height: auto;
        padding:80px 0 30px 0;
  }
}

.gostory-content{
    padding:50px 30px;
    overflow: hidden;
}

.gostory-content .pic-speed-des {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}
.gostory-content .pic-speed-des .des-1{
    position:absolute;
    top:10%;
    text-align: center;
    left: 39%;
}
.gostory-content .pic-speed-des .des-2{
    position:absolute;
    top:47%;
    font-size: 30px;
    font-weight: 700;
    color:rgb(15, 81, 50);
    text-align: center;
    left: 45%;
}
.gostory-content .pic-speed-des .des-3{
    bottom:10%;
    position:absolute;
    color: black;
    left: 39%;
    text-align: center;
}

.gostory-content .rate-box{
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}
.gostory-content .rate-box .number{
    display: flex;
    flex-direction: row;
    color: var(--pxft-blue);
}
.gostory-content .rate-box h1{
    font-size: 50px;
}

.gostory-pics-container{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.gostory-demo{
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 50px 0 0 0;
}

.gostory-social-container {
    position: relative;
}
.gostory-social-container .gallary{
    z-index: 1;
    position: relative;
}
.social-player-iframe{
    position: absolute;
    border: none;
    top:28%;
    left:14.5%;
    width:70%;
    height: 100%
}
.gostory-social-container .content{
    display: flex;
    justify-content: center;
    align-items: center;
    padding:20% 0;
    z-index: 1;
}
.gostory-social-container .social-media{
    color: rgb(255 255 255 / 60%);
}

.gostory-social-container .background{
    position:absolute;
    background-color: var(--pxft-blue);
    height:45%;
    width: 100%;
    z-index: 0;
    top:25%
}
.gostory-pics-container .imgbox{
    position: relative;
    margin:15px;
}
.gostory-pics-container .imgbox::after {
    content: "";
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(0deg, #fff 40%,transparent);
    opacity: .55;
}

.gostory-pics-container .left-imgbox{
    position: relative;
    margin:15px 15px 15px 0;
}
.gostory-pics-container .left-imgbox::after {
    content: "";
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(0deg, #fff 50%,transparent);
    opacity: .55;
}

.gostory-pics-container .right-imgbox{
    position: relative;
    padding:15px 0 15px 15px;
}

.gostory-pics-container .right-imgbox::after {
    content: "";
    position: absolute;
    top: 0px;
    left: 0px;
    width: 83%;
    height: 88%;
    background-image: linear-gradient(180deg, #fff,transparent);
    opacity: .8;
    margin: 15px 0 15px 15px;
}

.gostory-pics-container img{
    width: 100%;
    max-width: 280px;
}

.gostory-pics-container video{
    width: 100%;
    max-width: 280px;
}

@media only screen and (min-width: 1499px) {
  .gostory-content .pic-speed-des .des-1{
    left: 42%;
    }
  .gostory-content .pic-speed-des .des-2{
    left: 45%;
    }
    .gostory-content .pic-speed-des .des-3{
    left: 42%;
    }
}

@media only screen and (min-width: 599px) and (max-width: 899px) {
    .gostory-social-container .background{
        top:0;  
        height: 100%;
  }
  .gostory-content .pic-speed-des .des-1{
    left: 35%;
    }
  .gostory-content .pic-speed-des .des-2{
    left: 44%;
    font-size: 20px;
    }
    .gostory-content .pic-speed-des .des-3{
    left: 35%;
    }
}

@media only screen and (max-width: 599px) {
    .gostory-social-container .background{
        top:0;  
        height: 100%;
  }
  .gostory-content .pic-speed-des .des-1{
    left: 35%;
    }
  .gostory-content .pic-speed-des .des-2{
    left: 44%;
    font-size: 20px;
    }
    .gostory-content .pic-speed-des .des-3{
        left: 35%;
    }
.gostory-content .rate-box h1{
    font-size: 36px;
    }
}


.gostory-stepper-container{
    padding: 50px 30px;
    position: relative;
}
.gostory-stepper-container .steppers{
    display: flex;
    justify-content: center;
    height: 100%;
    width: 100%;
    position:sticky;
    top:10%;
}
.gostory-stepper-container .steppers .tabList{
    width: 100%;
    border-right: 1px solid var(--pxft-mildgrey);
}
.gostory-stepper-container .steppers .tab{
    padding:20px 60px; 
}

.gostory-stepper-container .steppers .tab h4{
    padding:10px 0;   
}

.gostory-stepper-container .steppers .tab-active{
    color: black;
    border-right: 3px solid var(--pxft-primary); 
    transition: 0.2s ease-in-out;
}

.gostory-stepper-container .stepper-videos{
    width: 100%;
    max-width: 1100px;
}
.gostory-stepper-container .stepper-videos .stepper-video{
    padding: 100px 60px;
    position: relative;
}
.stepper-videos .stepper-video .tabs{
    display: none;
}
.stepper-videos .stepper-video video{
    width: 100%;
    border-radius: 15px;
    box-shadow: 0 14px 28px rgb(0 0 0 / 25%), 0 10px 10px rgb(0 0 0 / 22%);
}
.stepper-videos .stepper-video img{
    width: 100%;
    border-radius: 15px;
    box-shadow: 0 14px 28px rgb(0 0 0 / 25%), 0 10px 10px rgb(0 0 0 / 22%);
}

@media only screen and (min-width: 599px) and (max-width: 899px) {
    .steppers{
        display: none !important;
    }
    .gostory-stepper-container .stepper-videos .stepper-video{
        padding: 30px 30px;
    }
    .stepper-videos .stepper-video .tabs{
        display: block;
        padding: 30px 10px;
    }
}

@media only screen and (max-width: 599px) {
    .steppers{
        display: none !important;
    }
    .gostory-stepper-container .stepper-videos .stepper-video{
        padding: 30px 30px;
    }
    .stepper-videos .stepper-video .tabs{
        display: block;
        padding: 30px 10px;
    }
}
.gostory-monetize-container{
    display:flex;
    flex-direction: column;
    background-color: var(--pxft-lightgrey);
    padding: 100px 20px;
}
.gostory-monetize-container h1{
    color:black;
    padding:20px;
    text-align: center;
}
.gostory-monetize-container p{
    padding:0 0 20px 0;
    text-align: center;
}
.gostory-monetize-column{
    display: flex;
    flex-direction: column !important;
    align-items: center;
    justify-content: center;
}
.gostory-monetize-column .item{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding:30px 0;
}
.gostory-monetize-column .item .MuiSvgIcon-fontSizeMedium{
    width: 60px;
    height:60px;
    padding: 20px 0;
}

.buyer-gostory-header-container{
    position: relative;
    overflow: hidden;
}
.buyer-gostory-header{
    padding: 350px 0 450px;
}
.buyer-gostory-header .background{
    position: absolute;
    top:0px;
    width: 100%;
    height:100vh;
    z-index: 0;
    margin:80px 0 0 0;
}
.buyer-gostory-header .background .bg-pic{
    width: 100%;
}
.buyer-gostory-header .title{
    flex-direction: column;
}
.buyer-gostory-header h1{
    color:white;
    font-size: 70px;
    text-align: center;
}
.buyer-gostory-header .label{
    color:white;
    position: absolute;
    width: 100%;
    bottom:50px;
    display: flex;
    flex-direction: column;
}
.buyers-gostory-content .overview{
    flex-direction: column;
    padding:100px 30px;
}
.buyers-gostory-content .overview .title{
    max-width: 450px;
    margin:20px;
    padding: 20px 40px;
    border-left: 5px solid var(--pxft-yellow);
    border-right: 5px solid var(--pxft-yellow);
}
.buyers-gostory-content .overview h1{
    text-align: center;
    font-size: 50px;
    line-height: 1.5;
    color:black;
}
.buyers-gostory-content .overview p{
    line-height: 3;
    text-align: center;
    padding:0 20px;
}
.buyers-gostory-content .overview span{
    color:black;
    font-weight: 500;
}
.buyers-gostory-content .demos .demo-content .icons{
    height:40px;
    width: 100px;
    padding:20px 0;
    display: flex;
    justify-content: space-between;
}
.buyers-gostory-content .demos .demo-content{
    color:white;
    flex-direction: column;
    margin:100px 0;
    padding: 120px 30px;
}
.buyers-gostory-content .demos .demo-content h1{
    padding: 20px 0;
    font-size: 45px;
}
.buyers-gostory-content .demos .demo-content p{
    font-size:medium;
    line-height: 2;
}
.buyers-gostory-content .stats{
    flex-direction: column;
    padding:80px 30px;
}
.buyers-gostory-content .stats h1{
    text-align: center;
    font-size: 60px;
    line-height: 1.5;
    color:black;
}
.buyers-gostory-content .stats h2{
    text-align: center;
    font-size: 40px;
    line-height: 2;
    color:black;
    padding: 30px 0;
}
.buyers-gostory-content .stats p{
    line-height: 3;
    text-align: center;
    padding:0 20px;
}
.buyers-gostory-content .stats span{
    font-size: var(--pxft-font-m);
    font-weight: 200;
}
.buyers-gostory-content .devices{
    display: flex;
    flex-direction: row;
    width: 100%;
    height:20px;
    max-width: 350px;
    color:var(--pxft-primary);
    align-items: center;
}
.buyers-gostory-content .devices p{
    font-size: var(--pxft-font-s);
    padding: 0 10px;
}
.buyers-gostory-content .brands{
    color:black;
    flex-direction: column;
    padding: 100px 30px 20px;
    width: 80%;
    text-align: center;
}
.buyers-gostory-content .story-demos{
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
}

.buyers-gostory-content .buyers-player-iframe{
    width:100%;
    height:100%;
    border:none;
}
.buyers-gostory-content .buyers-player-container{
    width:100%;
    height:150%;
}
.buyers-gostory-content .gostory-container{
    width:65%;
}
.buyers-gostory-content .gostory-media-container{
    position:absolute;
    top:-25%;
    left:30%;
    width:65%;
    height: 150%;
}

@media only screen and (max-width: 1299px) {
    .buyer-gostory-header .background{
        height: 100vh;
    }
    .buyer-gostory-header .background .bg-pic{
        height: 100vh;
    }
    .buyer-gostory-header{
        padding: 30vh 0;
    }
}
@media only screen and (max-width: 599px) {
    .buyer-gostory-header h1{
        font-size: 50px;
    }
    .buyers-gostory-content .overview h1{
        font-size: 40px;
    }
    .buyers-gostory-content .devices{
        justify-content: center;
    }
    .buyers-gostory-content .story-demos{
        width: 100%;
        padding: 0 0 70px 0;
    }
    .buyers-gostory-content .gostory-container{
        width: 80%;
    }
    .buyers-gostory-content .gostory-media-container{
        width: 80%;
        left: 20%;
    }
}



.video-header-container{
    position: relative;
    display: flex;
    align-items: center;
    padding: 150px 0 30px 0;
}
.max-height{
    max-height: 760px;
}
.video-header-container .phone-container{
    background: transparent url(https://www.pixfuture.com/wp-content/uploads/2019/10/ios_mockup2.png) no-repeat center;
    margin: auto;
    height: 100%;
    width: 100%;
    background-size: contain;
    z-index: 1;
}
.video-header-container .phone-container .video-container{
    position: relative;
    z-index: 1;
}

.video-header-container .phone-container .video{
    margin: 0;
    position: relative;
    left: 12%;
    box-shadow: none;
    width: 75%;
    z-index: 1;
}

.video-container{
    padding:0 30px;
}
.video-content .column-title{
    color:black;
    width:100%;
    text-align:center;
    padding:50px 0
}

.video-content .row-quotation{
    position: relative;
    background-color: var(--pxft-lightgrey);
    display: flex;
    align-items: center;
    padding:0 0 50px 0;
}
.video-content .row-quotation .card-container{
    position: relative;
    display: flex;
    align-content: center;
    justify-content: center;
    width: 100%;
    padding: 0 30px;
}

.video-content .numbercard-container{
    flex-direction: column;
    border: 1px solid var(--pxft-mildgrey);
    padding: 30px 35px;
    margin: 10px 20px;
    max-width: 300px;
}
.video-content .numbercard-container .numbercard-title{
    font-size: 55px;
    color: var(--pxft-blue);
}
.video-content .numbercard-container .numbercard-article{
    font-size: var(--pxft-font-s);
}

.video-content .get-started{
    position: relative;
    background-color: var(--pxft-lightgrey);
    display: flex;
    align-items: center;
    padding:50px 0;
}
.video-content .get-started .title{
    display: flex;
    flex-direction: column;
    padding:50px 30px;
}
.video-content .get-started .button{
    display: flex;
    justify-content: flex-end;
    padding:50px 30px;
}
.video-content .get-started .title h1{
    color: black;
    padding: 10px 0;
}
@media only screen and (min-width: 599px) and (max-width: 899px) {
    .video-content .get-started .title{
        justify-content: center;
        text-align: center;
    }
    .video-content .get-started .button{
        justify-content:center;
    }
  }
  
  @media only screen and (max-width: 599px) {
    .video-header-container{
        padding:90px 0 30px 0;
    }
    .video-header-container .phone-container .video-container{
        top:40%;
        height: 190%;
    }
    .video-header-container .phone-container .video{
        left: 12%;
        width: 75%;
        height: 60%;
    }
    .video-content .get-started .title{
        justify-content: center;
        text-align: center;
    }
    .video-content .get-started .button{
        justify-content:center;
    }
  }

  .video-mobile-container{
    position: relative;
    display: flex;
    align-items: center;
    padding: 130px 0;
  }
  .video-mobile-container .image_div{
    margin-top: 65px;
    margin-left: 60px;
    width: 50%;
    height: 600px;
    border-bottom: 1px solid #ccc;
    background-position-x: center;
    display: inline-block;
  }
  .video-mobile-container .iphone_background{
    background: transparent url(https://www.pixfuture.com/wp-content/uploads/2017/02/global-iphone-3.png) no-repeat top center;
    margin: auto;
    height: 500px;
    width: 77%;
    background-size: cover;
    transition: .3s;
  }
  .video-mobile-container  .secondAd{
    width: 80%;
    background-size: contain;
    background-repeat: no-repeat;
    margin: auto;
    top: 41.5%;
    left: 1px;
    position: relative;
    transition: .3s;
  }

  .instream-header-desktop-video-container video{
    border: 0.5rem solid #49435B;
    border-radius: 25px;
    width: 100%;
    height: 85%;
    -o-object-fit: cover;
    object-fit: cover;
    -o-object-position: center;
    object-position: center;
  }

  .instream-header-mobile-video-container video{
    border: 0.5rem solid #49435B;
    border-radius: 25px;
    width: 50%;
    height: 80%;
    -o-object-fit: cover;
    object-fit: cover;
    -o-object-position: center;
    object-position: center;
    margin:0 20px;
  }
  @media only screen and (max-width: 599px) {
    .instream-header-desktop-video-container video{
        display: none;
    }
    .instream-header-mobile-video-container video{
        height: 100%;
    }
  }


