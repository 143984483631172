.aboutus-header-container{
    position: relative;
    background-color: var(--pxft-lightgrey);
    height: 100vh;
    display: flex;
    align-items: center;
    padding: 130px 0;
    max-height: 760px;
  }
  @media only screen and (max-width: 599px) {
    .aboutus-header-container{
        height: 100%;
        max-height: 100%;
  }
}
  .aboutus-header-container img{
    width: 90%;
    max-width: 1000px;
    margin-left: auto;
    margin-right: auto;
  }
  .aboutus-content{
      padding:0 30px 100px 30px;
  }
  .aboutus-content .column-title{
    color:black;
    width:100%;
    text-align:center;
    padding:50px 0
  }
  .aboutus-content .quotation-container{
    position:absolute;
    right:0;
    top:20%;
  }
  
  .aboutus-content .aboutus-teamcard{
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding:40px 5px;
  }
  .aboutus-teamcard .avatar img{
      width: 100%;
      height: 150px;
      width: 150px;
      border-radius: 50%;
  }
  .aboutus-teamcard .avatar-circle{
    display: flex;
    width: 150px;
    height: 150px;
    max-height: 200px;
    max-width: 200px;
    border-radius: 50%;
    border: 2px solid var(--pxft-yellow);
    align-items: center;
    justify-content: center;
}
.aboutus-teamcard .avatar-circle a{
    font-weight: 500;
    color: var(--pxft-blue);
    padding: 0;
}
  .aboutus-teamcard h6{
      color:black;
      padding: 25px 0 5px 0;
  }
  .aboutus-teamcard p{
    text-align: center;
    width: 60%;
    white-space:pre-line;
}

  @media only screen and (min-width: 599px) and (max-width: 899px) {
    .basic-header-container{
      padding: 80px 0; } 
  }
  
  @media only screen and (max-width: 599px) {
    .basic-header-container{
      height: auto;
      padding: 60px 0px; } 
    .aboutus-content .quotation-container{
        position:relative;
        right:0;
        top:20%;
    }
  }


  .contact-header-container{
    position: relative;
    background-color: var(--pxft-lightgrey);
    height: 100vh;
    display: flex;
    align-items: center;
    padding: 130px 0;
    max-height: 760px;
  }
  @media only screen and (max-width: 599px) {
    .contact-header-container{
        height: 100%;
        max-height: 100%;
  }
}
  
  .contact-content{
      display: flex;
      justify-content: center;
      align-items: center;
  }
  .contact-content .map img{
    width: 100%;
  }
  .contact-content .map img:hover{
    cursor: pointer;
  }

  .blog-header-container{
    position: relative;
    display: flex;
    align-items: center;
    padding: 150px 30px 0 30px;
  }
  .blog-header-container .title{
    color: black;
    padding:20px 0;
  }
  .blog-header-container .divider{
    border-top: 1px solid var(--pxft-midgrey);
    width: 100%;
    padding:10px 0;
  }
  .blog-header-container .time{
    padding:10px 0;
    color: var(--pxft-midgrey);
    display:flex;
    flex-direction:row;
    align-items:center;
  }
  .blog-header-container .time .category{
    color:black;
    font-weight: 600;
    padding:0 10px;
  }
  .blog-header-container .sharebtn-container{
    display: flex;
    justify-content: flex-end;
  }

  .blog-content{
    padding:50px 30px;
  }
  .blog-content a{
    cursor: pointer;
  }

  .blog-content .content{
    width:100%
  }
  .blog-content .row{
    width:100%;
    display: flex;
    flex-direction: row;
    padding:10px
  }
  .blog-content .full-width{
    width:100%;
    display: flex;
    flex-direction: column;
    padding:10px
  }
  .blog-content .text-column{
    width:70%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .blog-content .pic-colomn{
    width:30%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .blog-content .content p{
    white-space:pre-wrap;
    line-height: 1.5;
    width: 100%;
  }
  .blog-content .content img{
    width: 300px;
    padding:0 20px;
  }
  .blog-content .content .italic{
    font-style: italic;
  }
  .blog-content .content .big-img{
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 80%;
  }
  .blog-content .content .big-img img{
    width: 600px;
  }
  @media only screen and (max-width: 599px) {
    .blog-content .row{
      flex-direction: column-reverse;
      align-items: center;
      justify-content: center;
      padding:0;
    }
    .blog-content .full-width{
      padding:0;
    }
    .blog-content .reverse{
      flex-direction: column !important;
    }
    .blog-content .text-column{
      width: 100%;
      padding:10px 0;
    }
    .blog-content .pic-column{
      width: 100%;
      padding:10px 0;
    }
    .blog-content .content img{
      width: 100%;
      padding:10px 0;
      display: flex;
      justify-content: center;
      align-items: center;
  }
  .blog-content .content .big-img img{
      width: 100%;
  }
}
  .blog-content .content .right-img{
    float:right;
  }
  .blog-content .content .left-img{
    float:left;
  }
  .blog-content .content .center-img{
    padding:40px
  }

  .blog-content .btn-container{
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding: 30px 0;
  }